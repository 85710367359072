import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DecimalInputDirective } from './directive/decimal-input.directive';



@NgModule({
  declarations: [DecimalInputDirective],
  imports: [
    CommonModule
  ],
  exports: [
    DecimalInputDirective
  ]
})
export class CoreModule { }
