import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddressComponent } from './address.component';

@NgModule({
    imports: [CommonModule],
    declarations: [AddressComponent],
    exports: [AddressComponent]
})
export class AddressModule {}
