import { Component, Injectable, Input } from '@angular/core';
import { addressModole } from './address-modole';

@Component({
    selector: 'app-address',
    templateUrl: './address.component.html',    
    styleUrls: ['./address.component.scss']
})

@Injectable({
    providedIn: 'root'
 })
export class AddressComponent {
    @Input() item: addressModole;     
    
    constructor() {
    }
}
