import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router) {}

    canActivate() {
        if (localStorage.getItem('isLoggedin')) {
            return true;
        }

        this.router.navigate(['/login']);
        return false;
    }

    // canActivate(
    //     route: ActivatedRouteSnapshot,
    //     state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    //         console.log(state.url)
    //     const isAuthenticated = true// Sua lógica de autenticação aqui
    //     if (isAuthenticated) {
    //       return true;
    //     } else {
    //       this.router.navigate(['/login']); // Redirecionar para a página de login se não estiver autenticado
    //       return false;
    //     }
    //   }
    }

