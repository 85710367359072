import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDecimalInput]'
})
export class DecimalInputDirective {

  @Input() decimalPlaces: number = 2;

  constructor(private el: ElementRef, private ngControl: NgControl, private renderer: Renderer2) {}

  ngOnInit() {
    // Verifica se o valor inicial é definido e é uma string
    if (this.ngControl.value !== null && typeof this.ngControl.value === 'string') {
      // Converte o valor inicial para número
      const numericValue = this.converteParaNumero(this.ngControl.value);

      // Atualiza o valor do ngModel com a versão numérica formatada
      this.ngControl.control.setValue(isNaN(numericValue) ? null : numericValue.toFixed(this.decimalPlaces));
    }
  }


  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    let inputValue: string = inputElement.value;

    // Remover caracteres não numéricos, exceto a vírgula
    inputValue = inputValue.replace(/[^0-9,]/g, '');

    // Substituir vírgulas duplicadas
    inputValue = inputValue.replace(/,+/g, ',');

    // Limitar o número de casas decimais
    const parts = inputValue.split(',');
    if (parts.length > 1) {
      parts[1] = parts[1].slice(0, this.decimalPlaces);
      inputValue = parts.join(',');
    }

    // Atualizar o valor no input
    this.renderer.setProperty(inputElement, 'value', inputValue);
  }

  private converteParaNumero(valor: string): number {
    // Substitui a vírgula por ponto e converte para número
    return parseFloat(valor.replace(',', '.'));
  }


}
